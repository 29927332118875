import React, { useEffect, useState } from 'react';
import styles from './ConsentModal.module.scss';
import { config } from '../../../config';
import { useConsents } from '../../../hooks/useConsents';
import GenericBtn from '../../Buttons/GenericBtn/GenericBtn';
import Loader from '../../Loaders/Loader/Loader';
import { ConsentPopupDataTypeSelect } from '../ContentModal/ExchangeDataAPI/RowDatatypesAPI/RowDatatypesAPI';
import GenericModal from '../GenericModal/GenericModal';
import { fontAwesome } from '../../../data/enums/fontAwesome';
import { ButtonContainer } from '../../Containers/ButtonContainer/ButtonContainer';
import { Button } from '../../Buttons/Button/Button';

/**
 * A component that handles all logic related to the consent popup
 * @param {object} props
 * @param {string} props.dataUserId ID of the Import Service
 * @param {string} props.dataProviderId ID of the Export Service
 * @param {'o2o' | 'm2o'} props.type If it's a One-To-One or Many-To-One exchange
 * @param {string[]} props.preConfiguredProviders If set, the user will not have an option to chose the data sources
 * @param {boolean} props.allowSelection If set to false, will default to sending all datatypes in the consent
 * @param {boolean} props.standalone If set to true, handles logic to show / close the popup within the component
 * @param {boolean} props.visible The default visibility state of the popup if set to standalone
 * @param {(consents: object[]) => any} props.onConsent Callback when clicking the consent button
 * @param {() => any} props.onClose Callback when clicking the close button in standalone mode
 * @param {() => onOpen} props.onOpen Callback when opening the popup in standalone mode
 * @param {(responses: {success: boolean}[]) => onOpen} props.onDataExchangeComplete Callback fired after all consent protocols received a response from VisionsTrust
 *
 * @todo One-To-One logic is incomplete, do not use o2o without verification first
 */
export const ConsentModal = ({
    dataUserId = null,
    dataProviderId = null,
    type = 'm2o',
    preConfiguredProviders = [],
    allowSelection = true,
    consentBtnContent = 'Partager mes données',
    standalone = false,
    visible = false,
    onConsent = () => {},
    onClose = () => {},
    onOpen = () => {},
    onDataExchangeComplete = () => {},
}) => {
    if (type === 'o2o') console.warn('One-To-One logic is incomplete, do not use o2o without verification first');

    const [consentPopupOpen, setConsentPopupOpen] = useState(visible);

    const { oneToOneConsents, manyToOneConsents, giveConsent } = useConsents();
    const [availableExchanges, setAvailableExchanges] = useState([]);
    const [error, setError] = useState(false);

    // One To One exchange
    const [selectedProvider, setSelectedProvider] = useState(null);
    const [exchange, setExchange] = useState(null);

    // Many To One exchange
    const [selectedProviders, setSelectedProviders] = useState([]);

    // Both
    const [selectedDataTypes, setSelectedDataTypes] = useState([]);

    /**
     * Handles selection of multiple providers in a consent popup
     * @param {object} provider The full provider object selected by the user
     * @param {React.MouseEvent<HTMLInputElement>} e The checkbox input event
     */
    const handleMultiProviderSelection = (provider, e) => {
        const exists = selectedProviders.findIndex((p) => p._id === provider._id);
        if (exists === -1 && e.target.checked) {
            setSelectedProviders((prev) => [...prev, provider]);
        } else if (exists !== -1 && !e.target.checked) {
            setSelectedProviders((prev) => {
                const newSelection = [...prev];
                newSelection.splice(exists, 1);
                return newSelection;
            });
        }
    };

    /**
     * Handles provider selection (automatic) on a one to one exchange
     * @param {object} provider The full provider object returned from the consent data
     */
    const handleProviderSelection = (provider) => {
        setSelectedProvider(provider);
        const selectedExchange = availableExchanges.find(
            (exch) => !!exch.datatypes.find((data) => data?.service?._id === provider?._id),
        );
        setExchange(selectedExchange);
    };

    /**
     * Handles user checkboxes for datatype selection
     * @param {React.MouseEvent<HTMLInputElement>} e Input checkbox event
     * @param {{_id: string; name: string; description: string; provenance: string}} datatype The DataType object selected
     */
    const handleDatatypeSelection = (e, datatype) => {
        const exists = selectedDataTypes.findIndex((p) => p._id === datatype._id);
        if (exists === -1) {
            setSelectedDataTypes((prev) => [...prev, datatype]);
        } else if (exists !== -1) {
            setSelectedDataTypes((prev) => {
                const newSelection = [...prev];
                newSelection.splice(exists, 1);
                return newSelection;
            });
        }
    };

    /**
     * @todo for o2o
     */
    const handleMultiProviderValidation = () => {
        console.log(selectedProviders);
    };

    /**
     * Handles the action of giving consent after datatype selection
     * @param {object} exch The exchange object on which to generate the different o2o consents
     */
    const handleConsent = async (exch) => {
        let finalSelectedDataTypes = [...selectedDataTypes];

        if (!allowSelection) {
            // Since the user is not allowed selection, default place all
            // datatypes as the datatype selection
            finalSelectedDataTypes = [...exch?.datatypes?.filter((dt) => !!dt?.userExport)?.map((dt) => dt.datatype)];
        }

        const consents = [];
        const buildConsentDataForProvider = (provider) => {
            const selectedProviderDatatypes = finalSelectedDataTypes.filter((dt) => dt.provenance === provider._id);

            const userExport = exch.datatypes.find((dt) => dt?.service?._id === provider._id)?.userExport;

            return {
                serviceImport: exch.serviceImport._id,
                datatypes: selectedProviderDatatypes.map((dt) => {
                    return {
                        ...dt,
                        id: dt?._id,
                        datatype: dt?.name,

                        // HARDCODE FOR NOW BUT
                        // TODO This should be a prior verification. Right now this only includes
                        // datatypes selected by the user, so the non selected are not included.
                        // However, these should be included with checked false for revocation
                        checked: !allowSelection ? false : true,
                    };
                }),
                emailExport: userExport?.email,
                emailImport: exch?.serviceImport?.type === 1 ? userExport?.email : exch?.userImport?.email,
                serviceExport: provider?._id,
                userKey: userExport?.userKey,
                purpose: exch?.purpose?._id || exch?.purpose,
                // TODO Handle this as data returned from the server somehow
                isNewAccount: exch?.serviceImport?.type === 1 ? false : false,
            };
        };

        for (const provider of exch?.providers) {
            // Check if the provider has at least one datatype selected by the user
            if (!finalSelectedDataTypes.find((dt) => dt.provenance === provider._id)) continue;

            // If the service is not in the pre-configured service array, skip
            if (!preConfiguredProviders.includes(provider?._id)) continue;

            consents.push(buildConsentDataForProvider(provider));
        }

        if (onConsent) onConsent(consents);

        const responses = await Promise.all(
            consents.map((consent) => {
                return giveConsent(consent);
            }),
        );

        // Then call the end callback with the result

        if (onDataExchangeComplete) onDataExchangeComplete(responses);
    };

    /**
     * Gathers the appropriate datatypes from the pre-configured datatypes passed in props
     * @param {object} exch The current exchange data configuration
     * @param {string[]} preConfiguredProviders The pre configured data sources available
     * @returns The array of datatypes according to the preconfigured providers
     */
    const getPreConfiguredProvidersDatatypes = (exch) => {
        if (!exch) return [];
        const datatypes = exch?.datatypes?.filter(
            (datatype) => preConfiguredProviders.includes(datatype?.service?._id) && !!datatype?.userExport,
        );
        return datatypes;
    };

    const handleModalVisibility = (visible) => {
        setConsentPopupOpen(visible);
    };

    useEffect(() => {
        let isMounted = true;

        const getAvailableExchanges = async () => {
            if (type === 'o2o') {
                if (!dataUserId || !dataProviderId) return;
                const data = await oneToOneConsents({ dataProviderId, dataUserId });

                if (!isMounted) return;

                if (!data || data.error) {
                    setError(true);
                    return;
                }

                setAvailableExchanges(data);
            } else {
                if (!dataUserId) return;

                const data = await manyToOneConsents({ dataUserId });

                if (!isMounted) return;

                if (!data || data.error) {
                    setError(true);
                    return;
                }

                const initialSelection = [];
                for (const exchange of data) {
                    for (const datatype of exchange?.datatypes) {
                        initialSelection.push(datatype?.datatype);
                    }
                }

                setSelectedDataTypes(initialSelection);
                setAvailableExchanges(data);
            }
        };

        getAvailableExchanges();

        return () => {
            isMounted = false;
        };
    }, [type, dataUserId, dataProviderId, oneToOneConsents, manyToOneConsents]);

    const modalContent = () => {
        return (
            <ConsentModalContent
                availableExchanges={availableExchanges}
                selectedDataTypes={selectedDataTypes}
                preConfiguredProviders={preConfiguredProviders}
                handleDatatypeSelection={handleDatatypeSelection}
                allowSelection={allowSelection}
                handleConsent={handleConsent}
                handleMultiProviderSelection={handleMultiProviderSelection}
                handleMultiProviderValidation={handleMultiProviderValidation}
                getPreConfiguredProvidersDatatypes={getPreConfiguredProvidersDatatypes}
                consentBtnContent={consentBtnContent}
            />
        );
    };

    if (!type || (type === 'o2o' && (!dataProviderId || !dataUserId)) || (type === 'm2o' && !dataUserId)) return null;

    if (error) return <>Error</>;

    if (!availableExchanges.length) return <Loader text="Chargement des échanges disponibles..." />;

    if (type === 'm2o') {
        if (standalone) {
            return (
                <>
                    <GenericBtn
                        onClickFc={() => {
                            handleModalVisibility(true);
                        }}
                        textContent={consentBtnContent}
                        hasArrow={false}
                    />
                    <GenericModal
                        open={consentPopupOpen}
                        closeModal={() => {
                            handleModalVisibility(false);
                        }}
                    >
                        {modalContent()}
                    </GenericModal>
                </>
            );
        } else {
            return modalContent();
        }
    }

    return <div>ConsentModal</div>;
};

const ConsentModalContent = ({
    availableExchanges,
    selectedDataTypes,
    preConfiguredProviders,
    handleDatatypeSelection,
    allowSelection,
    handleConsent,
    handleMultiProviderSelection,
    handleMultiProviderValidation,
    getPreConfiguredProvidersDatatypes,
    consentBtnContent,
}) => {
    const getProvidersFromDatatypes = (datatypes) => {
        const providers = {};
        for (const dataObject of datatypes) {
            if (providers[dataObject?.service?._id]) continue;
            else providers[dataObject?.service?._id] = dataObject?.service;
        }
        return Object.values(providers);
    };

    const [toggleDescriptionPurpose, setToggleDescriptionPurpose] = useState(true);

    const localConfig = localStorage.getItem('headai');
    const namespaceContext = localConfig ? JSON.parse(localConfig)?.namespace : null;

    return availableExchanges.map((exch, i) => (
        <div key={i}>
            <div className={styles.modal}>
                {!exch?.datatypes?.length ? (
                    <div>
                        <h2>Vous n'avez pas de données à partager.</h2>
                        <h3>
                            Assuez vous d'avoir bien créé un compte et généré de la donnée dans les plateformes sources
                            de vos données avant d'essayer de partager vos données.
                        </h3>
                    </div>
                ) : (
                    <>
                        <div style={{ width: '100%' }}>
                            {preConfiguredProviders.length ? (
                                <div style={{ width: '100%' }}>
                                    <h2>{exch?.purpose?.name}</h2>
                                    <div className={styles.modalContent}>
                                        <div className={styles.modalContentProviders}>
                                            <div className={styles.modalContentProvidersList}>
                                                {getProvidersFromDatatypes(
                                                    getPreConfiguredProvidersDatatypes(exch),
                                                )?.map((provider) => (
                                                    <img
                                                        key={provider?.name}
                                                        src={`${config.logoBaseUrl}/${provider?.logo}`}
                                                        alt={provider?.name}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                        <i className={fontAwesome.share}></i>
                                        <div className={`${styles.modalContentProviders}`}>
                                            <img
                                                className={styles.serviceImport}
                                                src={`${config.logoBaseUrl}/${exch.serviceImport?.logo}`}
                                                alt={exch.serviceImport?.name}
                                            />
                                            <p>{exch.serviceImport?.name}</p>
                                        </div>
                                    </div>
                                    <p
                                        className={`${styles.modalDescription} ${
                                            toggleDescriptionPurpose ? styles.modalHideDescription : ''
                                        }`}
                                        onClick={() => setToggleDescriptionPurpose((prev) => !prev)}
                                    >
                                        {namespaceContext === 'gen'
                                            ? exch.purpose?.description.replace("d'emploi et", '')
                                            : exch.purpose?.description}
                                    </p>
                                    <div className={styles.modalTable}>
                                        <table>
                                            <tbody>
                                                {getPreConfiguredProvidersDatatypes(exch)?.map((dt, j) => (
                                                    <React.Fragment key={dt?.datatype?.name + j}>
                                                        <ConsentPopupDataTypeSelect
                                                            data={dt}
                                                            datatype={dt?.datatype}
                                                            checked={selectedDataTypes.find(
                                                                (datatype) => datatype?._id === dt?.datatype?._id,
                                                            )}
                                                            onClick={handleDatatypeSelection}
                                                            allowSelection={allowSelection}
                                                        />
                                                    </React.Fragment>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <ButtonContainer className={styles.btn}>
                                        <Button
                                            size="smallMain"
                                            onclick={() => {
                                                handleConsent(exch);
                                            }}
                                        >
                                            {consentBtnContent}
                                        </Button>
                                    </ButtonContainer>
                                </div>
                            ) : (
                                <>
                                    <p>Choisissez la source de données</p>
                                    {exch.providers?.map((provider, y) => (
                                        <div key={y}>
                                            {allowSelection && (
                                                <input
                                                    type="checkbox"
                                                    onChange={(e) => handleMultiProviderSelection(provider, e)}
                                                />
                                            )}
                                            <label>- {provider.name}</label>
                                        </div>
                                    ))}
                                    <button
                                        onClick={() => {
                                            handleMultiProviderValidation();
                                        }}
                                    >
                                        Valider
                                    </button>
                                </>
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    ));
};
